import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { initAnalytics } from "./analytics.client";

Sentry.init({
  dsn: "https://d6e31e6d9531cbce7e777f2e1cac9e6e@o4508261827739648.ingest.de.sentry.io/4508912825925712",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

function InitAnalytics() {
  useEffect(() => {
    initAnalytics();
  }, []);
  return null;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <InitAnalytics />
    </StrictMode>
  );
});
